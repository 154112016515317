import React from "react";
import "./MobileCart.css";
import { useSelector } from "react-redux";

export default function MobileCart({
  handleDecrement,
  handleIncrement,
  handleDeleteItem,
  formatDateFull,
  formatTimeToAmPm,
}) {
  const { initialCartItems, initialCartEvent } = useSelector(
    (state) => state.cart
  );

  return (
    <div className="mobile-container">
      {initialCartItems?.map((el, i) => (
        <div className="mobile-cart-container" key={i}>
          <div
            className="mobile-delete-icon"
            onClick={() => handleDeleteItem(i)}
          >
            <div className="mobile-icon">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_539_14670)">
                  <path
                    d="M5.7308 1.54545C5.7308 1.03868 6.12631 0.668305 6.63308 0.668305H10.3422C10.8488 0.668305 11.2443 1.03868 11.2443 1.54545V2.50614H11.9126V1.54545C11.9126 0.670263 11.2174 0 10.3422 0H6.63308C5.75789 0 5.0625 0.670263 5.0625 1.54545V2.50614H5.7308V1.54545Z"
                    fill="#FF3E6C"
                  />
                  <path
                    d="M4.53701 17H12.4397C13.2016 17 13.7929 16.3297 13.7929 15.4963V5.17932H3.18359V15.4963C3.18359 16.3297 3.77489 17 4.53701 17ZM10.6185 6.77487C10.6185 6.59033 10.7681 6.44071 10.9526 6.44071C11.1372 6.44071 11.2868 6.59033 11.2868 6.77487V14.6692C11.2868 14.8538 11.1372 15.0034 10.9526 15.0034C10.7681 15.0034 10.6185 14.8538 10.6185 14.6692V6.77487ZM8.15411 6.77487C8.15411 6.59033 8.30373 6.44071 8.48826 6.44071C8.6728 6.44071 8.82241 6.59033 8.82241 6.77487V14.6692C8.82241 14.8538 8.6728 15.0034 8.48826 15.0034C8.30373 15.0034 8.15411 14.8538 8.15411 14.6692V6.77487ZM5.68974 6.77487C5.68974 6.59033 5.83935 6.44071 6.02389 6.44071C6.20842 6.44071 6.35804 6.59033 6.35804 6.77487V14.6692C6.35804 14.8538 6.20842 15.0034 6.02389 15.0034C5.83935 15.0034 5.68974 14.8538 5.68974 14.6692V6.77487Z"
                    fill="#FF3E6C"
                  />
                  <path
                    d="M2.84124 4.51109H14.1355C14.5969 4.51109 14.9709 4.13713 14.9709 3.67571C14.9709 3.2143 14.5969 2.84033 14.1355 2.84033H2.84124C2.37982 2.84033 2.00586 3.2143 2.00586 3.67571C2.00586 4.13713 2.37982 4.51109 2.84124 4.51109Z"
                    fill="#FF3E6C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_539_14670">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="mobile-order-container">
            <div
              className="mobile-event-box d-flex align-items-baseline"
              style={{ height: "30%" }}
            >
              <div className="col-6 text-start">
                <span>event</span>
              </div>
              <div className="col-6 text-start">
                <div className="mobile-event-detail">
                  <p>{initialCartEvent.EventName}</p>
                  <div className="mobile-event-date-time d-flex flex-column mt-2">
                    <div className="d-flex align-items-center just gap-2">
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_533_14576)">
                          <path
                            d="M3.80558 6.10004C3.80558 6.243 3.68862 6.35993 3.54569 6.35993H2.24622C2.10329 6.35993 1.98633 6.24297 1.98633 6.10004V5.23145C1.98633 5.08849 2.10329 4.97156 2.24622 4.97156H3.54569C3.68865 4.97156 3.80558 5.08852 3.80558 5.23145V6.10004Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M3.80558 8.38239C3.80558 8.52535 3.68862 8.64228 3.54569 8.64228H2.24622C2.10329 8.64228 1.98633 8.52532 1.98633 8.38239V7.5138C1.98633 7.37084 2.10329 7.25391 2.24622 7.25391H3.54569C3.68865 7.25391 3.80558 7.37087 3.80558 7.5138V8.38239Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M6.28217 6.10004C6.28217 6.243 6.16521 6.35993 6.02229 6.35993H4.72278C4.57983 6.35993 4.46289 6.24297 4.46289 6.10004V5.23145C4.46289 5.08849 4.57986 4.97156 4.72278 4.97156H6.02229C6.16524 4.97156 6.28217 5.08852 6.28217 5.23145V6.10004Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M6.28217 8.38239C6.28217 8.52535 6.16521 8.64228 6.02229 8.64228H4.72278C4.57983 8.64228 4.46289 8.52532 4.46289 8.38239V7.5138C4.46289 7.37084 4.57986 7.25391 4.72278 7.25391H6.02229C6.16524 7.25391 6.28217 7.37087 6.28217 7.5138V8.38239Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M8.72749 6.10004C8.72749 6.243 8.61052 6.35993 8.4676 6.35993H7.16809C7.02514 6.35993 6.9082 6.24297 6.9082 6.10004V5.23145C6.9082 5.08849 7.02517 4.97156 7.16809 4.97156H8.4676C8.61055 4.97156 8.72749 5.08852 8.72749 5.23145V6.10004Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M8.72749 8.38239C8.72749 8.52535 8.61052 8.64228 8.4676 8.64228H7.16809C7.02514 8.64228 6.9082 8.52532 6.9082 8.38239V7.5138C6.9082 7.37084 7.02517 7.25391 7.16809 7.25391H8.4676C8.61055 7.25391 8.72749 7.37087 8.72749 7.5138V8.38239Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M2.63582 3.0278C2.48055 3.0278 2.35352 2.90077 2.35352 2.74547V1.21763C2.35352 1.06233 2.48055 0.935303 2.63582 0.935303H3.22312C3.37839 0.935303 3.50545 1.06233 3.50545 1.21763V2.74547C3.50545 2.90074 3.37842 3.0278 3.22312 3.0278H2.63582Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M7.52254 3.0278C7.36727 3.0278 7.24023 2.90077 7.24023 2.74547V1.21763C7.24023 1.06233 7.36727 0.935303 7.52254 0.935303H8.10984C8.26511 0.935303 8.39217 1.06233 8.39217 1.21763V2.74547C8.39217 2.90074 8.26514 3.0278 8.10984 3.0278H7.52254Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M9.87735 2.08984C9.87735 2.08984 9.41122 2.08984 9.05554 2.08984C9.00858 2.08984 8.9217 2.08984 8.9217 2.1981V2.68136C8.9217 3.14276 8.66614 3.51814 8.08492 3.51814H7.54423C6.99312 3.51814 6.70745 3.14276 6.70745 2.68136L6.70748 2.22334C6.70748 2.1368 6.64702 2.08984 6.57491 2.08984C5.88642 2.08984 4.90091 2.08984 4.18799 2.08984C4.13503 2.08984 4.03436 2.08984 4.03436 2.22694V2.68136C4.03436 3.14276 3.8043 3.51814 3.19758 3.51814H2.65689C1.98505 3.51814 1.82011 3.14276 1.82011 2.68136V2.24497C1.82011 2.12238 1.70975 2.08984 1.64967 2.08984C1.29814 2.08984 0.864493 2.08984 0.864493 2.08984C0.714502 2.08984 0.591797 2.21255 0.591797 2.56457V9.85122C0.591797 9.79918 0.714502 9.92189 0.864493 9.92189H9.87732C10.0273 9.92189 10.15 9.79918 10.15 9.85122V2.56457C10.15 2.21255 10.0273 2.08984 9.87735 2.08984ZM9.54405 9.0432C9.54405 9.19319 9.42134 9.31589 9.27135 9.31589H1.47049C1.3205 9.31589 1.19779 9.19319 1.19779 9.0432V4.52804C1.19779 4.37805 1.3205 4.25535 1.47049 4.25535H9.27135C9.42134 4.25535 9.54405 4.37805 9.54405 4.52804V9.0432Z"
                            fill="#64C3C5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_533_14576">
                            <rect
                              width="9.55822"
                              height="9.55822"
                              fill="white"
                              transform="translate(0.59375 0.649292)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>{formatDateFull(initialCartEvent.startDate)}</p>
                    </div>
                    <div className="d-flex align-items-center just gap-2">
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_533_14558)">
                          <path
                            d="M10.878 2.0391C9.67448 0.83556 8.07411 0.172729 6.37215 0.172729C4.67018 0.172729 3.06981 0.83556 1.86627 2.0391C0.662735 3.24264 0 4.84278 0 6.54488C0 8.24697 0.662735 9.84711 1.86627 11.0507C3.06981 12.2542 4.67018 12.917 6.37215 12.917C8.07411 12.917 9.67448 12.2542 10.878 11.0507C12.0816 9.84711 12.7443 8.24697 12.7443 6.54488C12.7443 4.84278 12.0816 3.24264 10.878 2.0391ZM6.37215 11.8459C3.44924 11.8459 1.07103 9.46787 1.07103 6.54488C1.07103 3.62188 3.44924 1.24389 6.37215 1.24389C9.29505 1.24389 11.6733 3.62188 11.6733 6.54488C11.6733 9.46787 9.29505 11.8459 6.37215 11.8459Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M4.42207 9.92858C4.21667 9.81006 3.95378 9.88025 3.83485 10.0857C3.71636 10.2913 3.78687 10.5543 3.99227 10.6728C4.05988 10.712 4.13392 10.7306 4.20689 10.7306C4.35529 10.7306 4.49962 10.6535 4.57934 10.5157C4.69796 10.31 4.62767 10.0472 4.42207 9.92858Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M4.19773 3.22372C4.27095 3.22372 4.34531 3.20505 4.41324 3.16567C4.61833 3.04651 4.68829 2.78334 4.56907 2.57822C4.44963 2.37285 4.18681 2.30317 3.98162 2.4223C3.77625 2.54146 3.7066 2.80434 3.82579 3.00971C3.90554 3.147 4.04952 3.22372 4.19773 3.22372Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M2.39878 4.75474C2.46636 4.79351 2.54018 4.81221 2.61307 4.81221C2.76158 4.81221 2.90623 4.73517 2.98565 4.59706C3.10421 4.39168 3.03364 4.1288 2.82823 4.01025C2.62257 3.89141 2.35972 3.96195 2.24113 4.16758C2.12255 4.3733 2.19315 4.6359 2.39878 4.75474Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M6.36727 2.64051C6.36759 2.64051 6.36759 2.64051 6.36781 2.64051C6.60524 2.63991 6.79726 2.44728 6.79707 2.20988C6.7964 1.97246 6.60387 1.78043 6.36641 1.78101C6.12895 1.78129 5.93696 1.97396 5.9375 2.21132C5.93779 2.44846 6.1301 2.64051 6.36727 2.64051Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M2.40376 8.34425C2.19835 8.46309 2.12838 8.72594 2.24761 8.93141C2.32735 9.06886 2.47168 9.14571 2.6199 9.14571C2.69308 9.14571 2.76713 9.12704 2.83506 9.08762C3.04043 8.96882 3.11033 8.70558 2.99117 8.50046C2.87205 8.29509 2.60913 8.22506 2.40376 8.34425Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M2.46885 6.54944C2.46853 6.31198 2.2759 6.11998 2.03854 6.12024C1.80115 6.12053 1.60909 6.31316 1.60938 6.55026C1.60969 6.78769 1.80201 6.97971 2.03914 6.97971H2.03937C2.27686 6.97943 2.46917 6.78677 2.46885 6.54944Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M6.37476 10.4493C6.13772 10.4497 5.94531 10.642 5.94531 10.8794C5.94563 11.1168 6.13804 11.3091 6.3754 11.3088C6.61276 11.3088 6.80517 11.1162 6.80485 10.8788C6.80488 10.6417 6.61232 10.4493 6.37476 10.4493Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M10.3422 8.33979C10.1369 8.22098 9.87403 8.29117 9.75507 8.49658C9.63626 8.70195 9.70648 8.96483 9.91185 9.08371C9.97978 9.12286 10.0538 9.14147 10.1267 9.14147C10.2752 9.14147 10.4195 9.06475 10.499 8.92689C10.6179 8.72151 10.5476 8.4586 10.3422 8.33979Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M10.7062 6.10498C10.4688 6.10559 10.2767 6.29853 10.2773 6.5359C10.2773 6.53829 10.2773 6.54068 10.2773 6.54272C10.2773 6.54364 10.2773 6.54421 10.2773 6.54482C10.2773 6.78221 10.4696 6.97456 10.707 6.97456C10.9445 6.97456 11.1368 6.78221 11.1368 6.54482C11.1368 6.54332 11.1368 6.54154 11.1368 6.53978C11.1368 6.538 11.1368 6.5359 11.1368 6.53386C11.1362 6.29646 10.9435 6.10469 10.7062 6.10498Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M8.31677 3.1587C8.38428 3.19783 8.45813 3.21618 8.531 3.21618C8.6795 3.21618 8.82415 3.13914 8.90358 3.00103C9.02216 2.7953 8.95159 2.53245 8.74593 2.4139C8.54021 2.29563 8.27729 2.36617 8.15906 2.57189C8.04057 2.77756 8.11107 3.04047 8.31677 3.1587Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M8.3258 9.92648C8.12042 10.0453 8.05023 10.3081 8.16936 10.5136C8.24908 10.6511 8.39334 10.7281 8.54162 10.7281C8.61484 10.7281 8.68863 10.7095 8.75646 10.6701C8.96186 10.5513 9.03183 10.2884 8.91302 10.0829C8.79408 9.87751 8.53117 9.80728 8.3258 9.92648Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M10.1216 4.79951C10.1951 4.79951 10.2692 4.78052 10.3373 4.74111C10.5425 4.62166 10.6121 4.35878 10.4927 4.15369C10.3735 3.94829 10.1103 3.87892 9.90524 3.99808C9.70009 4.11721 9.63044 4.38038 9.74963 4.5855C9.82938 4.72272 9.97339 4.79951 10.1216 4.79951Z"
                            fill="#64C3C5"
                          />
                          <path
                            d="M9.07187 6.84621L6.81511 6.69229L6.58985 3.38693C6.58208 3.27322 6.48752 3.18494 6.37349 3.18494C6.25949 3.18494 6.16505 3.27322 6.15722 3.38693L5.93594 6.63233L5.57474 6.6077C5.52657 6.60439 5.47919 6.62124 5.44392 6.65422C5.40871 6.68716 5.38867 6.7333 5.38867 6.7816V7.25862C5.38867 7.30695 5.40871 7.35302 5.44392 7.38596C5.47629 7.4162 5.51889 7.43293 5.56292 7.43293C5.56681 7.43293 5.57076 7.4328 5.57474 7.43254L5.88283 7.41152L5.86059 7.73678C5.85658 7.79671 5.87751 7.85559 5.91848 7.89943C5.95946 7.94334 6.01684 7.96825 6.07693 7.96825H6.67011C6.67037 7.96825 6.67065 7.96825 6.67078 7.96825C6.79045 7.96825 6.8875 7.8712 6.8875 7.75147C6.8875 7.74093 6.85975 7.34477 6.85975 7.34477L9.07196 7.19394C9.16344 7.1877 9.23449 7.11171 9.23449 7.02004C9.23429 6.92844 9.16334 6.85246 9.07187 6.84621Z"
                            fill="#64C3C5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_533_14558">
                            <rect
                              width="12.7443"
                              height="12.7443"
                              fill="white"
                              transform="translate(0 0.172729)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>
                        doors open at{" "}
                        {formatTimeToAmPm(initialCartEvent.startTime)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-ticket-box d-flex text-start">
              <span className="col-6">ticket</span>
              <p className="col-6">{el.name}</p>
            </div>
            <div className="mobile-price-box d-flex text-start">
              <span className="col-6">price</span>
              <p className="col-6">{el.price} €</p>
            </div>
            <div className="mobile-quantity-box d-flex text-start">
              <span className="col-6">quantity</span>
              <div className="event-img-date col">
                <div className=" w-100 event-name ">
                  <span class="cart-input-wrapper d-flex justify-content-start align-items-center">
                    <button onClick={() => handleDecrement(i)}>-</button>
                    <input type="number" value={el.orderQty} id="quantity" />
                    <button onClick={() => handleIncrement(i)}>+</button>
                  </span>
                </div>
              </div>
            </div>
            <div className="mobile-total-box d-flex text-start">
              <span className="col-6">total</span>
              <p className="col-6">{el.price * el.orderQty} €</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
